<messages>["./index"]</messages>

<template>
  <v-row
    justify="center">
    <v-col
      cols="12"
      sm="8"
      md="4">
      <v-card>
        <form
          novalidate
          @submit.prevent="onSubmit">
          <v-card-title primary-title>
            <div
              class="text-h5"
              v-text="$t ('passwordReset.title')"/>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="identity"
              class="required"
              name="identity"
              :label="$t('identity')"
              :error-messages="requiredErrors(
                'identity',
                'required.identity')"
              @input="$v.identity.$touch"
              @blur="$v.identity.$touch"/>
            <v-alert v-model="showError" type="error" dismissible transition="scroll-y-transition">
              {{ $t ('error.noEmailSet') }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.back'"
              text
              @click="back"/>
            <v-btn
              v-t="'general.button.reset'" color="primary" :loading="isWorking"
              type="submit"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import {mapActions} from 'vuex'
  import {required} from 'vuelidate/lib/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {
    name: 'Index',

    mixins: [validationMixins],

    data () {
      return {
        identity: '',
        isWorking: false,
        showError: false
      }
    },

    validations: {
      identity: {required}
    },

    methods: {
      ...mapActions ('request', [
        'fetchData'
      ]),

      onSubmit () {
        if (!this.$v.$invalid) {
          this.resetPassword (this.identity)
        } else {
          this.$v.$touch ()
        }
      },

      resetPassword (identity) {
        this.isWorking = true
        this.showError = false
        return this.fetchData ({
          op: 'resetPassword',
          params: {
            identity: identity
          },
          cb: () => {
            this.$router.push ({name: 'passwordReset.done'})
          },
          cbError: (data, next) => {
            if (data?.result?.[0].code === 'error/syntax/missingvalue') {
              this.showError = true
            } else {
              next (data)
            }
          },
          cbFinal: () => {
            this.isWorking = false
          }
        })
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
